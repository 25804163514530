import React, { useState } from "react"
import ReactModal from "react-modal"
import { css } from "@emotion/core"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import { devForms, isBrowser } from "../utils/constants"

if (isBrowser) {
  ReactModal.setAppElement("body")
}

const modalStyle = css`
  z-index: 100000;
  .modal-content {
    overflow-x: hidden;
  }
`

const FormModal = ({
  children,
  showModal,
  modalTitle,
  errorText,
  onAfterOpen,
  onCaptchaComplete,
  submitFormSuccess,
  submitFormError,
  closeModal,
  blockSubmit,
  formPost,
  onAfterClose,
  formState,
  successMessage
}) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const handleSubmit = e => {
    e.preventDefault();
    if (!devForms && !formState.captcha) {
      return false
    }

    setIsLoading(true)
    return formPost()
      .then(submitFormSuccess)
      .then(() => {
        removeCaptcha()
        setIsLoading(false)
        setSubmitSuccess(true)
      })
      .catch((e) => {
        removeCaptcha()
        setIsLoading(false)
        setHasError(true)
        submitFormError(e)
      })
  }

  const removeCaptcha = () => {
    if (document && document.querySelector('.g-recaptcha-bubble-arrow')) {
      document.querySelector('.g-recaptcha-bubble-arrow').parentElement.remove()
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      onAfterOpen={() => {
        removeCaptcha()
        onAfterOpen()
      }}
      contentLabel="Minimal Modal Example"
      bodyOpenClassName="modal-open"
      className="modal-dialog"
      onAfterClose={() => {
        setHasError(false)
        setSubmitSuccess(false)
        removeCaptcha()
        onAfterClose()
      }}
      portalClassName={`ReactModalPortal`}
      style={{
        overlay: {
          zIndex: "1000000",
          overflow: 'scroll'
        },
      }}
    >
      <div className="modal-content" css={modalStyle}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {modalTitle}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {!isLoading && !submitSuccess && (
            <form action="/" onSubmit={handleSubmit} method="POST" className="modal-form">
              {children}
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                onChange={onCaptchaComplete}
                size="compact"
              />
              {hasError && (
                <div className="form-text text-danger">
                {errorText}
                </div>
              )}

            </form>
          )}
          {isLoading && (
            <div className="spinner-border modal-pending" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {submitSuccess && (
            <div className="modal-success">
              <h3>{successMessage}</h3>
            </div>
          )}
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-danger modal-close-btn"
            data-dismiss="modal"
            onClick={closeModal}
          >
            Close
          </button>
         
          <button
            type="button"
            className = {
              `btn btn-primary${submitSuccess ? ' d-none' : ''} modal-submit-btn`
            }
            disabled={blockSubmit}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </ReactModal>
  )
}

FormModal.defaultProps = {
    blockSubmit: false,
    modalTitle: 'Security Verification',
    errorText: 'There was an error sending this form. Please try again.',
    onAfterOpen: () => true,
    submitFormSuccess: () => true,
    submitFormError: () => true,
    onAfterClose: () => true
}

FormModal.props = {
  submitFormSuccess: PropTypes.func,
  submitFormError: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  onAfterOpen: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  blockSubmit: PropTypes.bool,
  onAfterClose: PropTypes.func,
  modalTitle: PropTypes.string,
  errorText: PropTypes.string,
  onCaptchaComplete: PropTypes.func.isRequired,
  children: PropTypes.element,
  formState: PropTypes.object.isRequired,
  successMessage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  formPost: PropTypes.func.isRequired
}

export default FormModal
