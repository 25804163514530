import React, { useState } from "react"
import ReactModal from 'react-modal'
import { css } from "@emotion/core"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from "@emotion/styled"
import FormModal from './form-modal'
import { temboBlue } from "../utils/constants"
import { submitGtagEvent } from "../utils/helpers"

const SubscriptionInput = styled.input`
  border: 2px solid;
  background: ${temboBlue};
  color: white;
  &::placeholder {
    color: white;
  }
  &:focus {
    background: #fff;
    color: ${temboBlue};
  }
  max-width: 400px;
`
ReactModal.setAppElement("#___gatsby")

const SubscriptionForm = () => {
  const [formState, setFormState] = useState({
    subscription_email  : '',
    captcha: null
  })
  const [showModal, setShowModal] = useState(false)
  const [blockSubmit, setBlockSubmit] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorText, setErrorText] = useState(undefined)

  const onCaptchaComplete = e => {
     setFormState(Object.assign({},
       formState, {
         captcha: e
       }
     ))
     setBlockSubmit(false)
  }


  const handleChange = e => {
    if (hasError && !showModal) {
      setHasError(false)
    }

    setFormState(
      Object.assign({},
        formState,
        {
          [e.target.name]: e.target.value
        }
      )
    )
  }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formState.subscription_email.length) {
      return false
    }
    setShowModal(true);
  }

  const formPost = () => {
    return addToMailchimp(formState.subscription_email)
  }

  const formSuccess = (data) => {
    if (data.result === 'error') {
      throw new Error(data.msg)
    }
    submitGtagEvent('form', 'newsletter', 'mailchimp')
    setFormState({
      subscription_email: '',
      captcha: null
    })
    return true
  }
  
  const submitFormError = (e) => {
    const eString = e.toString()
    if (eString.indexOf('already subscribed')) {
      setErrorText('This email address is already subscribed to the Tembo Education newslettter.')
    } else {
      setErrorText('There was an error sending this form. Please try again')
    }
  }

  return (
    <div className="row p-4 justify-content-center">
      <div className="col-12 mx-auto">
        <form
          className="form-inline align-items-center justify-content-center"
          onSubmit={handleSubmit}
          method="POST"
          action="/"
        >
          <h5 className="text-white d-inline mb-sm-0 mr-sm-3 mr-md-0">
            Get Free Parenting Tips
          </h5>

          <label className="sr-only" htmlFor="email">
            Enter Your Email
          </label>
          <div
            className="ml-md-5 flex-fill input-group"
            css={css`
              max-width: 500px;
            `}
          >
            <SubscriptionInput
              className="form-control form-control-lg"
              placeholder="Enter Your Email"
              aria-label="Enter Your Email"
              aria-describedby="basic-addon2"
              id="subscription_email"
              name="subscription_email"
              type="email"
              onChange={handleChange}
              successMessage="You have signed up to receive parenting tips for Tembo."
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-light text-primary">
                Send Tips
              </button>
            </div>
          </div>
        </form>
      </div>
       <FormModal
        formState={formState}
        submitFormSuccess={formSuccess}
        formPost={formPost}
        successMessage="You have successfully signed up to receive parenting tips from Tembo via email."
        submitFormError={submitFormError}
        blockSubmit={blockSubmit}
        onCaptchaComplete={onCaptchaComplete}
        showModal={showModal}
        closeModal={() => {
          setShowModal(false)
        }}
        errorText={errorText}
        path="newsletter_signup"
      />
    </div>
  )
}

export default SubscriptionForm
