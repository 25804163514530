import React, { useState } from 'react';
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Logo from '../images/Tembo.svg'
import { temboBlue, loginLink, signupLink } from '../utils/constants'

const BtnBar = styled.span`
        width: 22px;
        height: 2px;
        background-color: ${temboBlue};
        display: block;
        transition: all 0.2s;
        margin-top: 4px;
        ${props => props.position !== 'middle' ? `
            transform: rotate(0);
        ` : `opacity: 1;`
        }
    .collapsed & {
        ${props => props.position === 'middle' ? `opacity: 0;` : `
            transform: translate(3px) rotate(${props.position === 'top' ? '' : '-'}45deg);
            transform-origin: 10% ${props.position === 'top' ? '1' : '9'}0%;
        `
        }
    }
`


const NavLink = styled(Link)`
    padding: 1rem !important;
    font-size: 1.5rem;
    color: ${temboBlue} !important;
    &:hover,&:focus {
        color: #444 !important;
    }
`

const ThickButton = styled.span`
    border-width: 2px;
    display: inline;
    border-radius: 50px;
    width: 100%;
    appearance: none;
`

const NavCustom = styled.nav`
    @media (max-width: 991px) {
        .nav-item:not(:last-child) {
            border-bottom: 1px solid ${temboBlue};
        }
    }
`
const NavWrapper = styled.div`
    ${props => props.isLanding ?
        `
        @media (max-width: 991px) {
            background: white;
            ${props.navShow ? `
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            ` : ''}
        }
        ` : 
        `background: white;`
    }
    z-index: 100000;
`

const BtnLink = styled(NavLink.withComponent('a'))`
    &:hover, &:focus {
        text-decoration: none;
    }
    @media (max-width: 991px) {
       display: inline-flex;
       width: 50%;
    }
`

function NavBar({imagePath, isLanding}) {
    const [navShow, setNavShow] = useState(false)
    return (
    <NavWrapper className="justify-content-lg-center navbar navbar-expand-lg navbar-light w-100 shadow-sm" isLanding={isLanding} navShow={navShow}>
        <div className="container">
            <Link className="navbar-brand" to="/"><img src={Logo} alt="Tembo Education" css={css`height: 30px;`} /></Link>
               <button
                   aria-controls="basic-navbar-nav"
                   aria-label="Toggle Navigation"
                   className={`navbar-toggler border-0${navShow ? ' collapsed' : ''}`}
                onClick={() => setNavShow(!navShow)}
            >
                {
                    ['top', 'middle', 'bottom'].map(x => (
                        <BtnBar key={x} position={x} />
                    ))
                }
            </button>
      
            <div className={`navbar-collapse collapse${navShow ? ' show' : ''}`}>
            <NavCustom className="navbar-nav ml-auto">
            <div className="nav-item d-inline-flex align-items-center">
                <NavLink className="nav-link" to="/">Home</NavLink>
            </div>
            <div className="nav-item d-inline-flex align-items-center">
                <NavLink className="nav-link" to="/about">About</NavLink>
            </div>
            <div className="nav-item d-inline-flex align-items-center">
                <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </div>
            <div className="nav-item d-inline-flex my-auto">
                <BtnLink href="https://beta.temboeducationgroup.com/login"><ThickButton className="btn btn-lg btn-outline-primary">Log In</ThickButton></BtnLink>
                <BtnLink href="https://beta.temboeducationgroup.com/register"><ThickButton className="btn btn-lg btn-primary">Sign Up</ThickButton></BtnLink>
            </div>
            </NavCustom>
            </div>
        </div>
    </NavWrapper>
    )
}

NavBar.defaultProps = {
    isLanding: false
}
NavBar.props = {
    imagePath: PropTypes.string.isRequired,
    isLanding: PropTypes.bool
}

export default NavBar