import React from "react"
import { Global, css } from "@emotion/core"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "../styles/custom.scss"
import SEO from "./seo"
import Navbar from "./navbar"
import Footer from "./footer"

const fontFamily = [
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Helvetica Neue",
  "Arial",
  "Noto Sans",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
].join(", ")

const StaticLayout = ({
  seoDisabled,
  children,
  styles,
  isLanding,
  title,
  description,
}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            logo
          }
        }
      }
    `}
    render={data => (
      <div className="d-flex flex-column sticky-footer">
        <SEO disabled={seoDisabled} title={title} description={description} />
        <Global
          styles={css`
            @import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700,700i,900,900i");
            *,
            *:before,
            *:after {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            body {
              font-family: ${fontFamily};
              color: #444;
            }
            .sticky-footer {
              min-height: 100vh;
            }

            .main-section {
              min-height: 50vh;
            }

            .b-shadow {
              box-shadow: 8px 8px 0 rgba(38, 170, 226, 0.2);
              border: rgba(38, 170, 226, 0.6) 2px solid;
            }

            .display-text {
              font-weight: 300;
              font-size: 3.3rem;
              @media (min-width: 630px) {
                font-size: 3.5rem;
              }
              @media (min-width: 768px) {
                font-size: 4rem;
              }
            }
          `}
        />
        <Navbar imagePath={data.site.siteMetadata.logo} isLanding={isLanding} />
        <main className={`${styles} flex-fill main-section`}>{children}</main>
        <Footer />
      </div>
    )}
  />
)

StaticLayout.defaultProps = {
  isLanding: false,
  styles: "",
  description: "",
  title: "",
  seoDisabled: false,
}

StaticLayout.propTypes = {
  styles: PropTypes.string,
  isLanding: PropTypes.bool,
  children: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string,
  seoDisabled: PropTypes.bool,
}

export default StaticLayout
