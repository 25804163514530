import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import CurlyQuote from "../images/quotes-left.svg"

const AboutLeadText = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
`

const SectionStyled = styled.section`
  ${props =>
    props.blue &&
    `
    background: rgba(38,170,226,.2);
  `}
  .section-subhead:not(.display-4) {
    font-weight: 300;
    font-size: 3.3rem;
    @media (min-width: 630px) {
      font-size: 3.5rem;
    }
    @media (min-width: 768px) {
      font-size: 4rem;
    }
    @media (min-width: 1200px) {
      font-size: 5.5rem;
    }
    line-height: 1.7;
  }
  .section-text {
    font-size: 24px;
    line-height: 1.7;
    font-weight: 300;
    font-size: 1.5rem;
    @media (min-width: 630px) {
      font-size: 1.7rem;
    }
    .cite-link {
      color: inherit;
      border-bottom: 1px dotted;
      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom-style: solid;
      }
    }
    .quote-footer {
      font-size: 100%;
    }
  }
`

const TextSection = ({
  sectionClass,
  subheader,
  headerClass = "",
  text,
  children,
  ...props
}) => (
  <SectionStyled className={sectionClass} {...props}>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-9">
          <h1 className={`section-subhead ${headerClass}`}>{subheader}</h1>
          <AboutLeadText className="font-weight-light h4 section-text">
            {children}
          </AboutLeadText>
        </div>
      </div>
    </div>
  </SectionStyled>
)

export default ({ data }) => (
  <Layout
    title="Research"
    description="Learn more about the research behind Tembo Education's innovative learning program."
  >
    <TextSection
      blue={true}
      sectionClass=""
      subheader="Research"
      headerClass="text-primary"
      text="."
    >
      <AboutLeadText>
        <p>
          Our convenient, text-based early childhood education (ECE) is derived
          from studies by leading researchers. We employ evidence-based learning
          to <b>maximize your child's potential</b>.
        </p>

        <blockquote className="blockquote b-shadow bg-white p-4">
          <img src={CurlyQuote} alt="curly-quote" />
          <h3 className="my-3 text-primary">
            Education at the early stages of life is not seen as critical [...]
            a misapprehension that can have repercussions for the rest of their
            lives.
          </h3>
          <footer className="blockquote-footer text-primary quote-footer">
            <a
              href="https://s3-us-west-2.amazonaws.com/tembo-education-storage/public/Early+Childhood+Development+-+the+Unmet+Need+of+the+Century.pdf"
              className="cite-link"
            >
              <cite title="Source Title">IXL Center</cite>
            </a>
          </footer>
        </blockquote>
        <h2 className="my-5 text-primary display-4">
          Text-based learning studies
        </h2>

        <ul className="my-4">
          <li>
            <a
              href="https://ready4k.parentpowered.com/files/York%20&%20Loeb%20(November%202014).pdf"
              className="text-dark cite-link"
            >
              (York & Loeb, 2014) One step at a time: The effects of an early
              literacy text messaging program for parents of preschoolers
            </a>
          </li>
          <li>
            <a
              href="https://ready4k.parentpowered.com/files/Doss%20Fahle%20Loeb%20York%20(2018).pdf"
              className="text-dark cite-link"
            >
              (Doss, Fahle, Loeb & York, 2018) More than just a nudge:
              Supporting kindergarten parents with differentiated and
              personalized text-messages
            </a>
          </li>
        </ul>
      </AboutLeadText>
    </TextSection>
    <TextSection sectionClass="" subheader="Why ECE" headerClass="text-primary">
      <AboutLeadText>
        <ul>
          <li className="h4">
            <b>New research</b> shows that early <b>education</b> starting{" "}
            <b>from birth to age six</b> is the critical and{" "}
            <b>unmet need of this century</b>.
          </li>
          <li className="h4">
            <b>Studies</b> have shown that <b>every dollar invested</b> in early
            childhood education results in a return on investment of{" "}
            <b>$7-$13</b>.
          </li>
        </ul>
        <h3 className="my-4 text-primary">
          <a
            href="https://s3-us-west-2.amazonaws.com/tembo-education-storage/public/Early+Childhood+Development+-+the+Unmet+Need+of+the+Century.pdf"
            className="cite-link"
          >
            Read More
          </a>
        </h3>
        <blockquote className="blockquote b-shadow bg-white p-4">
          <img src={CurlyQuote} alt="curly-quote" />
          <h3 className="my-3 text-primary">
            Nationwide, 5.8 million children [...] are not on track to succeed
            when they begin kindergarten, lacking the necessary cognitive,
            physical, language, & social/emotional skills, to succeed in a
            formal learning environment.
          </h3>
          <footer className="blockquote-footer text-primary quote-footer">
            <a
              href="https://www.fastcompany.com/3062654/meet-the-billionaire-philanthropist-placing-big-bets-on-early-childho"
              className="cite-link"
            >
              <cite title="Source Title">J.B. Pritzker</cite>
            </a>
          </footer>
        </blockquote>
      </AboutLeadText>
    </TextSection>
    <TextSection
      sectionClass="bg-light"
      headerClass="text-primary display-4"
      subheader="Quality Learning for Your Child"
    >
      <p>
        Between the various facets of modern life,{" "}
        <b className="font-weight-bold">you have less time</b>, making it
        increasingly difficult to educate your child at home. With an endless
        number of apps and more information than ever at your fingertips, it is
        simplify{" "}
        <b className="font-weight-bold">inconvenient and overwhelming</b> for
        parents to find trustworthy information.
      </p>
      <p>
        This is why our team at Tembo Education created a convenient and
        accessible solution that doesn't add to your child's screen time. Our activities create
        successful learning outcomes and opportunities for you to meaningfully
        bond with your child in{" "}
        <b className="font-weight-bold">just 15 minutes a day</b>.
      </p>
      <p><Link to={`/#cta`} className="cite-link font-weight-bold text-primary">Get a sample activity</Link> and find out what Tembo Education can do for you and your child.</p>
    </TextSection>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
